import { PDFService } from '../core/services/pdf-service';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2, AfterContentChecked, AfterViewChecked, AfterContentInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormsModule, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { IFile, IFileExtended, FileType, IRouterFile, FileTypeUtils, IFileExtended1 } from '../core/models/file.model';
import {Helper } from '../core/services/helper.service';
import { IPart } from '../core/models/part.model';
import { PartServiceHttp } from '../core/services/part.servicehttp';
import { IRouter } from '../core/models/router.model';
import { RouterServiceHttp } from '../core/services/router.servicehttp';
import { AlertService } from '../core/services/alert.service';
import { AlertLevel, AlertReponse } from '../core/models/alert.model';
import { FileServiceHttp } from '../core/services/file.servicehttp';
import { SortCriteria} from '../core/models/sort.model';
import { Config } from '../core/shared/gatekeeper/config';
import { GateKeeperService} from '../core/shared/gatekeeper/user/user.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FileAddValidationService } from '../core/validators/file-add-validation.service';
import { type } from 'os';
import { FileUpdateValidationService } from '../core/validators/file-update-drawing-validation.service';
import { RouterState } from '../core/enums/router-state';
import { TouchSequence } from 'selenium-webdriver';
import { Permission } from '../core/enums/permissions';
import { MatRadioButton, MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { retry } from 'rxjs-compat/operator/retry';
import { FILE } from 'dns';
import { UserMaintenanceServiceHttp } from '../core/services/usermaintenance.servicehttp';
import { EngManCategory, EngManCategoryUtils } from '../core/enums/engManCategory';
import { ThrowStmt } from '@angular/compiler';
import {pairwise} from 'rxjs/operators';


@Component({
  selector: 'app-files',
  template: require('./files.component.html'),
  //styles: [require('./files.component.css')]
  styles: [require('./files.component.css').toString()]
})

export class FilesComponent implements OnInit, OnDestroy, AfterContentChecked, AfterContentInit {

  @ViewChild('filter', {static: false}) searchField: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) private readonly viewPort: CdkVirtualScrollViewport;
  private _fileSourceType: string = "file";
  fileSourceTypes = ["file","onshape"]
  files: IFileExtended1[] = [];
  validator: any = null;
  fileInfoFormGroup: FormGroup;
  fileReplaceFormGroup: FormGroup;  
  selectedFile: IFile = null;
  selectedFileReplace: IFile = null;
  latestActiveFiles: IFileExtended[] = [];
  fileToReplaceList: IFileExtended[];
  replaceableFiles: IFileExtended[] = null;
  selectedReplaceableFiles: IFileExtended[] = null;
  parts: IPart[] = null;
  fileTypes = FileTypeUtils.types; 
  engManCategories: string[] = EngManCategoryUtils.engManCategoryArray;
  dir = 'asc';
  private fileActionMode: string = "";
  fileToUploadData:string = "";
  checkForAssociatedParts:boolean = false;
  showAssociatedParts:boolean = false;
  hideShowNextFileReplaceButton: boolean = false;
  showAssociatedRouters:boolean = false;
  showAssociatedRoutersTable:boolean = false;
  showFileReplaceAssociatedRouters:boolean = false;
  showFileReplaceAssociatedRoutersTable:boolean = false;
  showFormControls:boolean = true;
  showFormControlsFileReplace:boolean = true;
  showSubmitFileReplaceButton:boolean = false;
  fileAssociatedRouters: IRouter[] = null;

  fileReplacementTitle:string = "select the file(s) to replace";

  private getFileSourceType = () => {
    var ret = this.fileSourceType;
    //console.log("FilesComponent.getFileSourceType ret:" + ret);
    return ret;
  
  };

  public get fileSourceType():string
  {
    return this._fileSourceType;
  }

  public set fileSourceType(val:string)
  {    
    this.resetFormDefaults();
    this._fileSourceType = val;
    this.updateFormGroup();   
  }

  public get canAddFile():boolean
  {
    return this.gkPermissions.hasPermission(Permission.CAN_MANAGE_ENGINEERING_FILES);
  }

  public canUpdateFile(f: any):Boolean {
    var file: IFileExtended1 = f;
    //console.log("FilesComponent.canUpdateFile rev:" + file.revision + " latestRevision:" + file.latestRevision);
 
    if (!this.gkPermissions.hasPermission(Permission.CAN_MANAGE_ENGINEERING_FILES))
        return false;

    var rev = !this.utils.isEmptyString(file.revision)? file.revision.toLowerCase() : "";
    var latestRev = !this.utils.isEmptyString(file.latestRevision)? file.latestRevision.toLowerCase() : ""; 
        
    return rev == latestRev;
  }

  public canReplaceFile(f: any):Boolean {
    var file: IFileExtended1 = f;

    if (file.type == FileType.DESIGN)
      return false;
   //console.log("FilesComponent.canReplaceFile rev:" + file.revision + " latestRevision:" + file.latestRevision);
    var rev = !this.utils.isEmptyString(file.revision)? file.revision.toLowerCase() : "";
    var latestRev = !this.utils.isEmptyString(file.latestRevision)? file.latestRevision.toLowerCase() : ""; 
        
    return rev == latestRev;
  }

  constructor(
    private userMaintenance:UserMaintenanceServiceHttp,
    private fileService: FileServiceHttp,
    private utils: Helper,
    private renderer: Renderer2,
    private partService: PartServiceHttp,
    private routerService: RouterServiceHttp,
    private pdfService: PDFService,  
    private alert: AlertService,
    private Config: Config,
    private gkPermissions: GateKeeperService 
    ) { }

// search properties
  public get lastTerm(){
    return this.fileService.lastSearchStr;
  }

  public get term(){
    return this.fileService.term;
  }

  public set term(val:string)
  {
    this.fileService.term = val;
  }

  public set includePastRevisions(val: boolean)
  {
    this.fileService.includePastRevisions = val;
  }

  public get includePastRevisions() {
    return this.fileService.includePastRevisions;
  }



  public isSearchDisabled(term:string):boolean {
    return this.utils.isEmptyString(term);
  }

  public get canIncludePastFileRevisions():boolean {
    return this.gkPermissions.hasPermission("CanViewPastFileVersions");
  }

  //end search properties



  ngOnDestroy(): void {
    //console.log('FilesComponent.ngOnDestroy');
  }

  ngOnInit() {
    //console.log('FilesComponent.ngOnInit');

    this.fileInfoFormGroup = new FormGroup({
      type: new FormControl('', Validators.required),
      updateEngineeringManualVersion: new FormControl(true),
      specificationName: new FormControl('', Validators.compose([
        Validators.required,
        this.makeAddUniqueSpecificationName()
      ])),
      engManCategory: new FormControl('',Validators.required),
      revision: new FormControl('', Validators.compose(
        [
          Validators.required,
          this.makeUpdateUniqueSpecificationNameVersion()
        ])      
      ),
      description: new FormControl('', Validators.required),
      fileUpload: new FormControl('', Validators.required),
      onShapeURL: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(/^https:\/\/diwmsi.onshape.com\/(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/)
      ])),
      holdState: new FormControl('2', Validators.required),
      holdStateNotes: new FormControl(),
      routerHoldStateNotes: new FormControl()
    });

    this.fileInfoFormGroup.get("onShapeURL").valueChanges.pipe(pairwise()).subscribe(([prev,next] : [any,any]) => 
      {
        console.log("FilesComponent.OnShapeURL.ValueChanges");

        if (Helper.isUndefined(this.validator) || !this.validator.onShapeURLValidation.show)
        {
          return;
        }
          
        prev = !this.utils.isEmptyString(prev)? prev.trim() : "";
        next = !this.utils.isEmptyString(next)? next.trim() : "";

        console.log("...prev",prev);
        console.log("...next",next);

        if (next != prev)
        {
          setTimeout(() => {
         
            console.log("FilesComponent.OnShapeURL.ValueChanges...setting value:" + next + " length:" + next.length);
            this.fileInfoFormGroup.get("onShapeURL").setValue(next, { emitEvent: false });
            this.updateFormGroup();
          })      
        }      
      })


    this.validator = new FileAddValidationService(this.fileInfoFormGroup, this.partService, this.getFileSourceType);
  
    this.fileReplaceFormGroup = new FormGroup({
      holdStateFileReplace: new FormControl('2', Validators.required),
      holdStateNotesFileReplace: new FormControl()
    })

    this.userMaintenance.initUserInfo();    

    this.initFiles();
  }

  initFiles() {
    //retrieve last search result and scroll position and scroll to it.
    var scrollToIndex = this.fileService.lastIndex; 
      setTimeout(()=>{     
          this.viewPort.scrollToIndex(scrollToIndex);
          //console.log("scrolled to Index:" + scrollToIndex);   
        },0);
  }

 
  updateFilesAndSearchResults(val:IFileExtended1[])
  {
    var tmp = this.utils.arrayOrEmpty(val);
    tmp.sort(Helper.sortIFileSpecificationNameRevision);
    tmp = tmp.slice();
    this.files = null;
    this.files = tmp;
    this.fileService.searchedFiles = null;
    this.fileService.searchedFiles = tmp;
  }

  get formType():FileType {
    var ret = FileType.UNKNOWN;
    
    if (!this.utils.isUndefined(this.fileInfoFormGroup) &&
        !this.utils.isUndefined(this.fileInfoFormGroup.controls.type.value)) 
        {
          ret = this.fileInfoFormGroup.controls.type.value;
        }
        return ret;
  }

  set formType(val:FileType) {
   
    if (!this.utils.isUndefined(this.fileInfoFormGroup) &&
        !this.utils.isUndefined(val)) 
        {
          this.fileInfoFormGroup.controls.type.setValue(val);
        }
  }

  get formSpecificationName():string {
    var ret = '';
    
    if (!this.utils.isUndefined(this.fileInfoFormGroup) &&
        !this.utils.isUndefined(this.fileInfoFormGroup.controls.specificationName.value)) 
        {
          ret = this.fileInfoFormGroup.controls.specificationName.value;
        }

        return ret;
  }



  set formSpecificationName(val:string) {
    if (!this.utils.isUndefined(this.fileInfoFormGroup.controls.specificationName))
      this.fileInfoFormGroup.controls.specificationName.setValue(val);
  }


  get formEngManCategoy():string {
    var ret = "";
    
    if (!this.utils.isUndefined(this.fileInfoFormGroup) &&
        !this.utils.isUndefined(this.fileInfoFormGroup.controls.engManCategory.value)) 
        {
          ret = this.fileInfoFormGroup.controls.engManCategory.value;
        }
        return ret;
  }

  set formEngManCategory(val:string) {
   
    if (!this.utils.isUndefined(this.fileInfoFormGroup) &&
        !this.utils.isUndefined(val)) 
        {
          this.fileInfoFormGroup.controls.engManCategory.setValue(val);
        }
  }

  get formRevision():string {
    var ret = '';
    
    if (!this.utils.isUndefined(this.fileInfoFormGroup) &&
        !this.utils.isUndefined(this.fileInfoFormGroup.controls.revision)) 
        {
          ret = this.fileInfoFormGroup.controls.revision.value;
        }

        return ret;
  }

  set formRevision(val:string) {
    if (!this.utils.isUndefined(this.fileInfoFormGroup.controls.revision)) {
      if (this.utils.isUndefined(val))
         val = null;
      //console.log('files-components.formRevision val:' + val);
      this.fileInfoFormGroup.controls.revision.setValue(val);
    }
  }


  get formDescription():string {
    var ret = '';
    
    if (!this.utils.isUndefined(this.fileInfoFormGroup) &&
        !this.utils.isUndefined(this.fileInfoFormGroup.controls.description.value)) 
        {
          ret = this.fileInfoFormGroup.controls.description.value;
        }

        return ret;
  }

  set formDescription(val:string) {
    if (!this.utils.isUndefined(this.fileInfoFormGroup.controls.description)) {
      if (this.utils.isUndefined(val))
         val = null;
      //console.log('FilesComponent.formDescription val:' + val);
      this.fileInfoFormGroup.controls.description.setValue(val);
    }
  }

  get formFileName():string {
    var ret = '';
    
    if (!this.utils.isUndefined(this.fileInfoFormGroup) &&
        !this.utils.isUndefined(this.fileInfoFormGroup.controls.fileUpload.value)) 
        {
          ret = this.fileInfoFormGroup.controls.fileUpload.value;
        }

        return ret;
  }

  set formFileName(val:string) {
    if (!this.utils.isUndefined(this.fileInfoFormGroup.controls.fileUpload)) {
      if (this.utils.isUndefined(val))
         val = null;
      //console.log('FilesComponent.formFileName val:' + val);
      this.fileInfoFormGroup.controls.fileUpload.setValue(val);
    }
  }


  get onShapeURL():string {
    var ret = '';
    
    if (!this.utils.isUndefined(this.fileInfoFormGroup) &&
        !this.utils.isUndefined(this.fileInfoFormGroup.controls.onShapeURL.value)) 
        {
          ret = this.fileInfoFormGroup.controls.onShapeURL.value;
        }

        return ret;
  }

   set onShapeURL(val:string) {
    if (!this.utils.isUndefined(this.fileInfoFormGroup.controls.onShapeURL)) {
      if (this.utils.isUndefined(val))
         val = "";
      //console.log('FilesComponent.set_onShapeURL');
      if (this.fileInfoFormGroup.controls.onShapeURL.value != val)
      {
        //console.log("...setting onShapeUrl = val:" + val + " val.length:" + val.length);
        this.fileInfoFormGroup.controls.onShapeURL.setValue(val);
      }
    }
  } 



/*** CUSTOM VALIDATOR BEGIN ****************/

  private makeAddUniqueSpecificationName(): ValidatorFn {
    const _self = this;
    return (control:AbstractControl) : { [key:string]: any} | null => {
    var ret = null;
    const f = _self.formFile;
      
      if (_self.fileActionMode == "ADD" &&
          !_self.utils.isEmptyArray(_self.latestActiveFiles) &&
          f != null && f != undefined &&
          _self.utils.listContainsItem(_self.latestActiveFiles, f, this.compSpecificationName))
        ret = {'uniqueSpecificationName':{valid: false}}

      //console.log('FilesComponent.uniqueSpecificationName ret:' + ret );
      return ret;
    }
  }

  private makeUpdateUniqueSpecificationNameVersion(): ValidatorFn {
    const _self = this;
    return (control:AbstractControl) : { [key:string]: any} | null => {
    var ret = null;
    const f = _self.formFile;
      
      if (_self.fileActionMode == "UPDATE" &&
          !_self.utils.isEmptyArray(_self.latestActiveFiles) &&
          control != null && control != undefined && !_self.utils.isEmptyString(control.value) &&
          !this.utils.isUndefined(f) &&
         _self.utils.listContainsSimilarDifferentItem(
          _self.latestActiveFiles, 
            f, 
            _self.cmpSpecNameRev)) 
        ret = {'uniqueSpecificationNameVersion':{valid: false}}
        
      //console.log('FilesComponent.UpdateUniqueSpecificationNameVersion ret:' + ret );
      return ret;
    }
  }

compSpecificationName(a1: IFile, a2: IFile) {
  return Helper.cmpStringAlphaNumeric(a1.specificationName, a2.specificationName);
}

  /*** CUSTOM VALIDATOR END ****************/


  filterDrawingsOrSpecs(charSent: string) {
    if (charSent == '')  
    {
      this.clearSearchFilter();
    } 
    else
    {
    }
  }

  clearSearchFilter() {
    this.searchField.nativeElement.value = '';
  }

  patchFormGroup(f: IFileExtended1) {
    //console.log('FilesComponent.patchFormGroup');
    //console.log(f);
    this.fileInfoFormGroup.patchValue({
      type: f.type,      
      specificationName: f.specificationName,
      engManCategory: f.engManCategory,
      revision: this.getNextRevision(f),
      description: f.description,
      updateEngineeringManualVersion: true
    });
  }


  updateFormGroup() {
    //console.log('FilesComponent.updateFormGroup');
   
    //redo the validation
    this.validator.update();

    this.checkForAssociatedParts = false;
    this.showAssociatedParts = false;
    this.showAssociatedRouters = false;
    this.showAssociatedRoutersTable = false;
    this.showFormControls = true;
  }

  get formFile() :IFileExtended1 {
    if (this.fileInfoFormGroup)
      return this.fromFormGroup(this.fileInfoFormGroup.getRawValue());
    else
      return null;
  }

  private getSourceURL(fileName:string):string {
    var ret:string = '';
   
    if (this.fileSourceType == "onshape")
      ret = this.onShapeURL;

    return ret;
  }

  fromFormGroup(info): IFileExtended1 {
    console.log("FilesComponent.fromFormGroup");
    console.log(info);
    var fileName:string = (info.specificationName + '_' + info.revision + '.pdf').toLowerCase()
    
    return {
      id: (this.utils.isExistingItem(this.selectedFile) ? this.selectedFile.id : null ),
      type: info.type,
      name: fileName,
      specificationName: info.specificationName,
      engManCategory: info.engManCategory,
      revision: info.revision,
      description: info.description,
      url: '',
      sourceUrl: this.getSourceURL(fileName),
      revisionDate: info.revisionDate,
      updateManualRevision: info.updateEngineeringManualVersion,
      replacedby: "",
      latestRevision: "",
      active: true,
      inUse: false
    };
  }

  scrollChangeHandler(e:number)
  {
    //console.log("scrollChangeHandler:" + e);

    this.fileService.lastIndex = e;
  }

  onSorted(event: Event)
  {     
    var elem:Element = (event.target as Element);
    var elementId: string = elem.id;
    var elementClass: string = elem.className;    

    //console.log('FilesComponent.onSorted dir:' + this.dir + ' col:' + elementId);
    
    if ( this.dir == 'asc') 
    {
      switch (elementId)
      {
        case 'colType':
          this.files = [...this.files.sort(this.sortByTypeDesc)];
          break;  
        case 'colRevision':
          this.files = [...this.files.sort(this.sortByRevisionDesc)];      
          break; 
        case 'colDescription':
          this.files = [...this.files.sort(this.sortByDescriptionDesc)];
          break;  
        case 'colFileName':
        default:
          this.files = [...this.files.sort(this.sortByFileNameDesc)];
          break;     
      }
      this.dir = 'desc';
    }
    else
    {
      switch (elementId)
      {
        case 'colType':
          this.files = [...this.files.sort(this.sortByTypeAsc)];
          break;  
        case 'colRevision':
          this.files = [...this.files.sort(this.sortByRevisionAsc)];      
          break; 
        case 'colDescription':
          this.files = [...this.files.sort(this.sortByDescriptionAsc)];
          break;  
        case 'colFileName':
        default:
          this.files = [...this.files.sort(this.sortByFileNameAsc)];
          break;     
      }
      this.dir = 'asc';
    }
  }

  
  sortByFileNameAsc(a1: IFile, a2: IFile) { 
    return Helper.cmpString(a1.specificationName, a2.specificationName); 
  }

  
  
/*   sortByFileNameAscRevisionAsc(a1: IFile, a2: IFile) { 
    var cmp = Helper.cmpString(a1.specificationName, a2.specificationName);
    if (cmp == 0)
      cmp = Helper.cmpString(a1.revision, a2.revision);
    return cmp; 
  } */
  
  
  sortByFileNameDesc(a1: IFile, a2: IFile) { 
    return Helper.cmpStringDesc(a1.specificationName, a2.specificationName); 
  }  
  
  sortByTypeAsc(a1: IFile, a2: IFile) { 
    return Helper.cmpString(a1.type, a2.type); 
  }
  
  sortByTypeDesc(a1: IFile, a2: IFile) {
    return Helper.cmpStringDesc(a1.type, a2.type); 
  }
  
  sortByRevisionAsc(a1:IFile, a2: IFile) { 
    return Helper.cmpString(a1.revision.toString(), a2.revision.toString()); 
  }
  
  sortByRevisionDesc(a1: IFile, a2: IFile) {
    return Helper.cmpStringDesc(a1.revision.toString(), a2.revision.toString()); 
  }

  sortByDescriptionAsc(a1: IFile, a2: IFile) { 
    return Helper.cmpString(a1.description, a2.description); 
  }
  
  sortByDescriptionDesc(a1: IFile, a2: IFile) {
    return Helper.cmpStringDesc(a1.description, a2.description); 
  } 

  ngAfterContentChecked() {
    //console.log('FilesComponent.ngAfterContentChecked isVisible:');

    if (!this.checkForAssociatedParts && 
      this.fileActionMode == "UPDATE" && 
      !this.utils.isUndefined(this.selectedFile) && 
      this.selectedFile.type != FileType.DESIGN)
    {
      this.getAssociatedParts();
    } 
  }

  sortByPartNumberRev(a: IRouter, b: IRouter) {
    const ret = Helper.cmpStringAlphaNumeric(a.partNumber, b.partNumber);
    if (ret == 0) {
      return Helper.cmpNumber(a.revision, b.revision);
    } else {
      return ret;
    }
  }

  private updateToLatestVersion(latestVersionFile:IFileExtended1, files:IFileExtended1[])
  {
    var tmp:IFileExtended1[] = this.utils.arrayOrEmpty(files);
    var name:string = latestVersionFile.specificationName.toLowerCase();
    var revision:string = latestVersionFile.revision;
    var fileFamily:IFileExtended1[] = tmp.filter(x=>x.specificationName.toLowerCase() == name);

    if (!this.utils.isEmptyArray(fileFamily))
    {      
      for (let i = 0; i < fileFamily.length; i++)
        fileFamily[i].latestRevision = revision;
    }
  }

  replaceFileInFileList(oldFile:IFileExtended1, newFile: IFileExtended1) {
    if (this.utils.isUndefined(oldFile) || 
        this.utils.isUndefined(newFile))
      return;
    var tmp = this.removeFileFromFileList(oldFile, this.files);
    tmp.push(newFile);
    this.updateToLatestVersion(newFile, tmp);
    this.updateFilesAndSearchResults(tmp);
  }

    
  canDeleteFile(f: IFileExtended1): Boolean {
    if (f.type != FileType.DESIGN)
      return !f.inUse;

    var rev = !this.utils.isEmptyString(f.revision)? f.revision.toLowerCase() : "";
    var latestRev = !this.utils.isEmptyString(f.latestRevision)? f.latestRevision.toLowerCase() : ""; 
        
    return rev == latestRev;
  }

  
  addLatestVersion(file: IFileExtended1) {
    this.fileActionMode = "UPDATE";
    this.addUpdateFile = file;
    this.validator = new FileUpdateValidationService(this.fileInfoFormGroup, this.getFileSourceType, file);
    this.overlayOpen(file);;
  }

  


  getNextRevision(file:IFileExtended1):string
  {
    console.log("FilesComponent.getNextRevision");
    console.log(file);
    console.log();

    if (file == null)
      return  "A";
    
    const disallowedVersionNumber:string = "IOQSXZ";
    var revision = file.revision;
    var nextRevision:string = revision.toUpperCase();
    var nextRevisionASCII:number = 0;

    if (
      this.utils.isEmptyString(revision) ||
      revision == "-" ||
      revision == "0")
        return "A";

    do {
      nextRevisionASCII = nextRevision.charCodeAt(0) + 1;
      nextRevision = String.fromCharCode(nextRevisionASCII);
    } while(disallowedVersionNumber.indexOf(nextRevision) > -1)

    //console.log("FilesComponent.getNextRevision rev:" + revision + " nextRev:" + nextRevision);
    return nextRevision;
  }

  isNewLatestVersion(f: IFile) {
    return this.utils.isExistingVersionedItem(f) && !this.utils.existsMatchingItem(this.files, f, this.cmpSpecNameRev);
  }

  cmpSpecNameRev(a: IFile, b: IFile) {
    if (!a || !b) {
    return -1;
    }

    const ret = Helper.cmpStringAlphaNumeric(a.specificationName, b.specificationName);
    if (ret == 0) {
      return Helper.cmpStringAlphaNumeric(a.revision, b.revision);
    } else {
      return ret;
    }
  }

  setDescription(fileName: string, callback:Function) {

    //console.log('FilesComponent.setFileNameAndDescription fileName:' + fileName);
    
    if (this.formType == FileType.DRAWING)
    {
      this.partService.getPartByNumber(fileName).subscribe(part => {
        //console.log(part);
        if (!this.utils.isUndefined(part))
          this.formDescription = part.description;
        else 
          this.fileInfoFormGroup.controls.description.setValue("");     
        callback();
      })
    }
    else
    {
      callback();
    }    
  }

  fileUploadChange(event)
  {
    //console.log('File.Component.fileUploadChange');
    //get the file from the input file  hidden control
    var fileToUpload = event.target.files[0];
    //console.log('File.Component.fileUploadChange fileName:' + (fileToUpload!=null?fileToUpload.name:"NULL"));
    //immediately reset it to nothing
    event.target.value = '';
    if (fileToUpload == null)
    {
      this.formFileName = null;
      return;
    }    

    var fileName = fileToUpload.name.substring(0,fileToUpload.name.length-4);

    //console.log("FilesComponent.fileUploadChange fileName:" + fileName);
    this.formFileName = fileName;
    
    var fileReader:FileReader = new FileReader();

    fileReader.readAsBinaryString(fileToUpload);

    fileReader.onloadend = (e) => {
      this.fileToUploadData = btoa(fileReader.result.toString());
    }   

    if (this.validator.canAutoSetFileName)
      this.formSpecificationName = fileName;
    
    if (this.validator.canAutoSetDescription)
    {      
      this.setDescription(fileName, () => {
        this.updateFormGroup();
      });
    }
    else {
      this.updateFormGroup();
    }
  }

  toggleIncludePastRevisions()
  {

    this.includePastRevisions = !this.includePastRevisions;
  }


  setFileList(fileList:IFileExtended1[]) {
    this.updateFilesAndSearchResults(fileList);
    //after each search result, scroll to the first position
     setTimeout(()=>{     
          this.viewPort.scrollToIndex(0);
        },0);
  }

  searchFiles(e:Event)
  {    
    this.fileService.searchFiles(this.term, this.includePastRevisions).subscribe(response => 
      {
        this.setFileList(response)  
        //default value of checkbox is false for subsequent searches
        this.includePastRevisions = false;   
      });
  }

  getAssociatedParts()
  {
    //console.log("FilesComponent.getAssociatedParts");
    //-- For updating Drawing files, get PartDrawing (composite files) Associations
    if (this.fileActionMode == "UPDATE")
    {
      this.partService.getPartsAppPartAssociations(this.selectedFile.id).subscribe( parts => {
        this.parts = parts;
        this.checkForAssociatedParts = true;

        if (Array.isArray(this.parts) && this.parts.length)
        {
          if (this.parts.length > 0)
          {
            this.showAssociatedParts = true;
          }
          else
          {
            this.showAssociatedParts = false;
          }
        }
        else
        {
          this.showAssociatedParts = false;
        }
      });
    }
    else
    {
      this.showAssociatedParts = false;
      this.checkForAssociatedParts = false;
    }
      
  }
 
  nextOnClick()
  {
    //console.log('FilesComponent.nextOnClick');

    this.validator.nextClicked = true;

    //-- Hide previous form entry
    this.showFormControls = false;

    //-- Get associated routers
    this.routerService.getRoutersAssociatedWithFile(this.selectedFile.id).subscribe( routers => {
      this.fileAssociatedRouters = this.utils.arrayOrEmpty(routers);
      this.fileAssociatedRouters.sort(this.sortByPartNumberRev);

      if (!this.utils.isEmptyArray(this.fileAssociatedRouters))
      {
        //-- Show new listing of active routers associated with file being updated
        this.showAssociatedRoutersTable = true;
      }
      this.fileInfoFormGroup.controls.holdStateNotes.setValue("");
  
    });

    this.showAssociatedRouters = true;
  }

  

  submitOnClick()
  {
    if (this.fileInfoFormGroup.valid)
    {
      //this.formValidationMessage = "Valid";
      this.submitFile();
    }
    else
    {
      //this.formValidationMessage = "InValid";
      //console.log('FilesComponent.submitOnClick.fileInfoFormGroup.valid: ' + this.fileInfoFormGroup.valid);
    }
  }

  submitFile() 
  {
    //console.log('FilesComponent.submitFile');
    
    const file = this.formFile;

    //-- Add file to upload: fileData 
    file.fileData = this.fileToUploadData;
    
    var fileDataLength = file.fileData.length;
   

    //-- Set holdState and holdStatenotes
    if (this.fileActionMode == "ADD")
    {
      file.holdState = -1; //-- no hold state for adding new files
      file.holdStateNotes = "";
    }
    else
    {
      file.holdState = this.fileInfoFormGroup.controls.holdState.value;
      file.holdStateNotes = this.userMaintenance.concatLines(false, this.fileInfoFormGroup.controls.holdStateNotes.value);
    }

    
      
        if (this.fileActionMode == "ADD")
        {
          this.alert.showYesNo(
            'Continue with adding new file?', 
            AlertLevel.WARNING,
            (res: AlertReponse) => {
              if (res == AlertReponse.YES) 
              {
                this.fileService.createVersionedFile(file).subscribe(newFile => {
                  newFile.inUse = !this.utils.isEmptyArray(this.fileAssociatedRouters);
                  newFile.active = true;    
                  this.replaceFileInFileList(file,newFile);
                  this.closeOverlay();
                });
              }
            }
          );
        }
        else //UPDATE 
        {
          if (!this.utils.isEmptyArray(this.fileAssociatedRouters)) // UPDATE
          {
            //-- UPDATE file version
            this.alert.showYesNo(
              //'Adding a new file version will place associated routers on hold and create PENDING new versions for ' +
              //'all ACTIVE routers associated with the current version of this file. NoMuda will point' +
              //' associated PENDING routers to the latest file.'
              'If you click Yes, all ACTIVE routers associated with the file being updated will be placed on hold. ' + 
              'Also, NoMuda will create new, PENDING versions of these routers and point them to the updated File version.', 
              AlertLevel.WARNING,
              (res: AlertReponse) => {
                if (res == AlertReponse.YES) 
                {
                  this.fileService.createVersionedFile(file).subscribe(newFile => {     
                    newFile.inUse = !this.utils.isEmptyArray(this.fileAssociatedRouters);
                    newFile.active = true;      
                    this.replaceFileInFileList(file,newFile);
                    this.closeOverlay();
                  });
                }
              }
            );
          }
          else{
            this.fileService.createVersionedFile(file).subscribe(newFile => 
              {
                newFile.inUse = !this.utils.isEmptyArray(this.fileAssociatedRouters);
                newFile.active = true;    
                this.replaceFileInFileList(file,newFile);
                this.closeOverlay();
              });
          }
        }
      
    
  }
  
  deleteFile(file: IFile) {
    this.alert.showYesNo('Are you sure you want to delete file ' + file.name + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      //console.log(ar);
      //console.log(ar == AlertReponse.OK);
      //console.log(AlertReponse.OK);
      if (ar == AlertReponse.YES) {
        this.fileService.deleteFile(file.id).subscribe(res => {       
           this.removeFilesFromFileList([file.id]);
        });
      }
    });
  }

  fileReplace(file: IFile) { 
        
    if (file.type == FileType.DRAWING)
      this.fileReplacementTitle = "Select the drawing(s) to replace";
    else if (file.type == FileType.SPEC)
      this.fileReplacementTitle = "Select the spec(s) to replace";
    else
      this.fileReplacementTitle = "Select the file(s) to replace";

    this.fileReplacementTitle = this.fileReplacementTitle.concat(" with ",file.specificationName);

    this.overlayFileReplaceOpen(file);
  }

  getFileTemplateDefault(): IFileExtended1 {
    return {
      id : null,
      name: null,
      specificationName: null,
      engManCategory: null,
      revision: "",
      description: null,
      type: null,
      url: null,
      sourceUrl: null,
      revisionDate: new Date(),
      tokenID: Config.gkParameters.TokenID,
      holdState: null,
      updateManualRevision: true,
      replacedby:"",
      latestRevision:"",
      active: true,
      inUse:false
    };
  }

  private addUpdateFile:IFileExtended1 = null;
 
  addFile() {
    this.fileActionMode = "ADD";
    const f = this.getFileTemplateDefault();
    this.addUpdateFile = f;
    this.validator = new FileAddValidationService(this.fileInfoFormGroup, this.partService, this.getFileSourceType);
    this.overlayOpen(f);
  }

  viewFile(file: IFileExtended) {
    this.pdfService.show(file.url);
  }


  closeOverlay() {
    this.onFileOverlayClosing(this);
    document.getElementById('FilesOverlay').style.display = 'none';
  }

  getSourceFileType(file: IFile):string
  {
    var ret:string = "file";
    if (
      !Helper.isUndefinedObj(file) &&
      !Helper.isUndefined(file.url) &&
      file.url.indexOf("diwmsi.onshape.com") > -1 )
    {
      ret = "onshape";    
    }
    return ret;
  }


  private onFileOverlayClosing(fc:FilesComponent)
  {
    //console.log("FilesComponent.onFileOverlayClosing");
    fc.selectedFile = null;
    fc.onShapeURL = null;
  }

  overlayOpen(file: IFileExtended1) {
   
    this.fileAssociatedRouters = [];
    this.selectedFile = file;

    this.resetFormDefaults();

    this.patchFormGroup(file);
    this.updateFormGroup();

    this.fileService.getFilesExt1().subscribe( theFiles => {
      this.latestActiveFiles = this.utils.arrayOrEmpty(theFiles);     

      document.getElementById('FilesOverlay').style.display = 'block';
      const modal = document.getElementById('FilesOverlay');

      // When the user clicks anywhere outside of the modal, this closes it
      window.onclick = this.getWindowOnClickFunction(modal);


    });
  }

  private getWindowOnClickFunction(modal)
  {
    var myThis = this;
    return function(event) {
        
      if (event.target == modal) {
        myThis.onFileOverlayClosing(myThis);
        modal.style.display = 'none';
        $('.modal').find("input:not([type=hidden]),select")
          .val('')
          .end()
      }
    };
  }


  overlayFileReplaceOpen(file: IFile) {
    this.initFileReplaceForm();
    this.selectedFileReplace = file;

    this.fileService.getReplaceableFiles().subscribe( files => {
      this.replaceableFiles = this.utils.arrayOrEmpty(files);
      this.replaceableFiles = this.replaceableFiles.filter(x => x.type == this.selectedFileReplace.type
        && x.id != this.selectedFileReplace.id);
      this.replaceableFiles.sort(Helper.sortIFileNameRevision);

      document.getElementById('FileReplaceOverlay').style.display = 'block';
    });
    
    // When the user clicks anywhere outside of the modal, this closes it
    const modal = document.getElementById('FileReplaceOverlay');
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = 'none';
        //clearSelectedType;
        $('.modal').find("input:not([type=hidden]),select")
          .val('')
          .end()
      }
    };
  }

  closeFileReplaceOverlay() {
    document.getElementById('FileReplaceOverlay').style.display = 'none';
    this.clearSelectedFileReplacement();
  }

  initFileReplaceForm()
  {
    this.hideShowNextFileReplaceButton=false;
    this.showSubmitFileReplaceButton = false;
    this.hideShowNextFileReplaceButton = false;
    this.showFormControlsFileReplace = true;
    this.showSubmitFileReplaceButton = false;
    this.showFileReplaceAssociatedRouters = false;
    this.fileToReplaceList = new Array<IFileExtended>();
    this.clearSelectedFileReplacement();
  }

  clearSelectedFileReplacement()
  {
    if (this.selectedFileReplace != undefined || this.selectedFileReplace != null)
    {
      this.selectedFileReplace = null;
    }
  }

  onDeleteReplacedFile(f:IFileExtended)
  {
    if (!Helper.sIsEmptyArray(this.fileToReplaceList))
      this.fileToReplaceList = this.fileToReplaceList.filter(x=>x.id != f.id) || [];
  }


  showNextFileReplaceButton():boolean
  {
    return !Helper.sIsEmptyArray(this.fileToReplaceList);
  }

  nextFileReplaceOnClick()
  {
    //console.log('FilesComponent.nextFileReplaceOnClick');

    if (!Helper.sIsEmptyArray(this.fileToReplaceList))
    {
      //-- hide showNextFileReplace button
      this.hideShowNextFileReplaceButton = true;

      //-- Hide previous form entry
      this.showFormControlsFileReplace = false;

      //-- Show submitFileReplaceButton
      this.showSubmitFileReplaceButton = true;

      
      this.fileReplacementTitle = "Hold ACTIVE routers associated with replaced files";
      //this.fileReplaceFormGroup.controls.holdStateFileReplace.setValue("2"); //NO CHANGE

      //clear notes
      this.fileReplaceFormGroup.controls.holdStateNotesFileReplace.setValue("");
      this.showFileReplaceAssociatedRouters = true;

      //-- Get selected files associated routers
      var ids = this.getFileIdsToReplace();
      this.routerService.getRoutersAssociatedWithFiles(ids).subscribe( routers => {
        this.fileAssociatedRouters = this.utils.arrayOrEmpty(routers);
        

         if (this.fileAssociatedRouters.length > 0)
        {          
          this.fileAssociatedRouters = this.fileAssociatedRouters.filter(r => r.state == RouterState.ACTIVE);
          //-- Show new listing of active routers associated with files being replaced
          this.showFileReplaceAssociatedRoutersTable = true;
         // this.fileReplaceFormGroup.controls.holdStateFileReplace.setValue("5");
          this.fileAssociatedRouters.sort(this.sortByPartNumberRev);
        }
        else
        {
          this.showFileReplaceAssociatedRoutersTable = false;
        }
 
      });
    }
  }

  onSelectFileToReplaceHandler(selectedFiles:IFileExtended[])
  {
    //console.log('FilesComponent.onSelectFileToReplaceHandler');
    //console.log(selectedFiles);
    if (Helper.sIsEmptyArray(this.fileToReplaceList))
      this.fileToReplaceList = new Array<IFileExtended>();
    this.fileToReplaceList = this.fileToReplaceList.concat(selectedFiles);

    setTimeout(()=>{     
      this.selectedReplaceableFiles = [];
        },0);
  }

  makeHoldStateNotes():string
  {
    var ret:string = "";
    var fileName = this.selectedFileReplace.specificationName;
    var replacedFileNames = this.getFileNamesToReplace();
    var holdNotes = this.fileReplaceFormGroup.controls.holdStateNotesFileReplace.value

    if (this.utils.isEmptyString(fileName) || this.utils.isEmptyString(replacedFileNames))
      return ret;
         
    ret = ret.concat(fileName," replaces:", replacedFileNames);

    if (!this.utils.isEmptyString(holdNotes))
      ret = this.userMaintenance.concatLines(true, ret, holdNotes)    
    else
      ret = this.userMaintenance.concatLines(true, ret);
    
    return ret;
  }

  submitFileReplaceOnClick()
  {
    var routerHoldState: number = this.fileReplaceFormGroup.controls.holdStateFileReplace.value;
    var routerHoldStateNotes: string = this.makeHoldStateNotes();
    var tokenID: string = Config.gkParameters.TokenID;
    var returnValue: string = "";

    this.alert.showYesNo('Are you sure you want to continue with the file replacement(s)?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      if (ar == AlertReponse.YES) {
        var ids = this.getFileIdsToReplace();
        this.fileService.filesReplace(this.selectedFileReplace.id, ids, routerHoldState, routerHoldStateNotes, tokenID).subscribe(res => {
          returnValue = res.toString();
          this.removeFilesFromFileList(ids)
          //-- Close overlay
          this.closeFileReplaceOverlay();
        });
      }
    });
  }

  removeFileFromFileList(file:IFileExtended1, files:IFileExtended1[]): IFileExtended1[] {
    //console.log("FilesComponent.removeFileNameRevFromFileList files.len:"+ files.length);
    if (this.utils.isUndefined(file) || this.utils.isUndefined(file.id)) //adding file, nothing to remove
      return this.utils.arrayOrEmpty(files);

    var id:string = file.id.toLowerCase();
    var tmp = this.utils.arrayOrEmpty(files);
    tmp = tmp.filter(x => x.id.toLowerCase() != id );
    console.log("...ret files.len:" + tmp.length);
    return tmp;
  }

  removeFilesFromFileList(ids: string[]) {
    //console.log("FilesComponent.removeFilesFromFileList ids.length:" + ids.length);
    if (!Helper.sIsEmptyArray(this.files) &&
      !Helper.sIsEmptyArray(ids)){
        var tmp = this.utils.arrayOrEmpty(this.files);
        tmp = tmp.filter(x => !this.utils.listContainsString(ids,x.id));
        //console.log("FilesComponent.removeFilesFromFileList tmp.length:" + tmp.length);
        this.updateFilesAndSearchResults(tmp);
      }
 
  }

  getFileIdsToReplace()
  {
    //console.log('FilesComponent.getFileIdsToReplace');
    var ret = new Array<string>();

    if (!Helper.sIsEmptyArray(this.fileToReplaceList))
    {
      for (let i=0; i<this.fileToReplaceList.length; i++)
        ret.push(this.fileToReplaceList[i].id);
    }
  
    return ret;
  }

  getFileNamesToReplace()
  {
    //console.log('FilesComponent.getFileIdsToReplace');
    var ret = "";

    if (!Helper.sIsEmptyArray(this.fileToReplaceList))
    {
      for (let i=0; i<this.fileToReplaceList.length; i++)
      {
        var name = this.fileToReplaceList[i].specificationName;
        if (this.utils.isEmptyString(name))
          continue;

        if (i==0)
          ret = ret.concat(name);
        else
          ret = ret.concat(",",name)
      }
    }
  
    return ret;
  }

  
    // This can only happen when Adding
  onFileTypeChange(e: any) {
      //console.log('FilesComponent.onFileTypeChange');  
        
    this.resetFormDefaults();
    this.updateFormGroup();                  
  }

  public resetFormDefaults()
  {
    this.validator.reset();
    this.formRevision = this.getNextRevision(this.addUpdateFile);
    this._fileSourceType = this.getSourceFileType(this.selectedFile);
  }

  ngAfterContentInit(){
    //console.log('FilesComponent.ngAfterContentInit');

    $(document).ready(function(){
      $('.SearchField').focus();
    })
  }


}

