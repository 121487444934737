import { Injectable, EventEmitter } from '@angular/core';
import {IPart, IEtchingInfo } from '../models/part.model';
import { Helper } from './helper.service';




@Injectable()
export class PartDomainService {
  selectedPart: IPart = null;
  etchingInfo: IEtchingInfo = null;


  private _term:string = "";

  public get term():string {
    return this._term;
  }

  public set term(val:string)
  {
    if (
      val===null ||
      val === undefined)
      {
        this._term = '';
      }
      else
      {
        this._term = val;
      }
  }


  
  constructor(private helper: Helper) { }



}