
import { Component, OnChanges, OnInit, DoCheck, AfterContentChecked, AfterViewChecked, AfterViewInit, AfterContentInit,OnDestroy, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { IReport, IDemandItemRouterOnHoldReport } from '../../core/models/report.model';
import { Helper } from '../../core/services/helper.service';
import { AlertService } from '../../core/services/alert.service';
import { ReportServiceHttp } from '../../core/services/report.servicehttp';
import { Router } from '@angular/router';
import { SortCriteria} from '../../core/models/sort.model';

@Component({
  selector: 'app-demanditemrouteronhold',
  template: require('./demanditemrouteronhold.component.html'),    
  styles: [require('./demanditemrouteronhold.component.css')],
  providers: 
  []
    
})
export class DemandItemRouterOnHoldComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked, OnDestroy, AfterContentInit {
  reports: IDemandItemRouterOnHoldReport[]; 
  selectedReport: IReport;
  public term: string;  
  dir ="asc";  
  @ViewChild('reportForm' , {static: true}) reportForm: ElementRef;  
  @ViewChild('inputName' , {static: false}) inputName; 
  
  
  isLoading = true;

  constructor(
    private reportService: ReportServiceHttp,
    private alert: AlertService,
    private navRouter: Router,
    private utils: Helper   
    ) { }


  ngOnDestroy() {
      console.log('reports.component.ngOnDestroy');      
    }

  ngOnInit() {
    console.log('reports.component.ngOnInit');    

    this.reportService.getDemandItemRouterOnHoldReport().subscribe(reports => {
      console.log('reports.component- Get Reports call to report service');
      this.isLoading = false;
      this.reports = reports;
      })  
    }

    onSorted(criteria: SortCriteria)
    {    
        var elementId: string = (event.target as Element).id;
        if(elementId.substring(0,3) == 'col')
        {
          if(criteria.sortDirection === 'desc'){          
            if(criteria.sortColumn == 'itemnumber')
            {           
             this.reports.sort(this.sortByItemNumberDesc)  
            }
            else if (criteria.sortColumn == 'itemdescription')
            {
              this.reports.sort(this.sortByItemDescriptionDesc)
            }
            else if (criteria.sortColumn == 'onHoldReason')
            {
              this.reports.sort(this.sortByOnHoldReasonDesc)
            }
            else if (criteria.sortColumn == 'reported')
            {
              this.reports.sort(this.sortByReportedDesc)
            }
            else if (criteria.sortColumn == 'datetype')
            {
              this.reports.sort(this.sortByDateTypeDesc)
            }
            else if (criteria.sortColumn == 'priority')
              {
                this.reports.sort(this.sortByPriorityDesc)
              }
            
          }
          else {
            if(criteria.sortColumn == 'itemnumber')
            {
              this.reports.sort(this.sortByItemNumberAsc)
            }
            else if (criteria.sortColumn == 'itemdescription')
            {
              this.reports.sort(this.sortByItemDescriptionAsc)
            }
            else if (criteria.sortColumn == 'onHoldReason')
            {
              this.reports.sort(this.sortByOnHoldReasonAsc)
            }
            else if (criteria.sortColumn == 'reported')
            {
              this.reports.sort(this.sortByReportedAsc)
            }
            else if (criteria.sortColumn == 'datetype')
            {
              this.reports.sort(this.sortByDateTypeAsc)
            }
            else if (criteria.sortColumn == 'priority')
            {
              this.reports.sort(this.sortByPriorityAsc)
            } 
          }
        }        
    }
  


sortByItemNumberAsc(a1: IDemandItemRouterOnHoldReport, a2: IDemandItemRouterOnHoldReport){ 
    return Helper.cmpStringAlphaNumeric(a1.itemnumber, a2.itemnumber); 
}

sortByItemNumberDesc(a1: IDemandItemRouterOnHoldReport, a2: IDemandItemRouterOnHoldReport) { 
    return Helper.cmpStringAlphaNumericDesc(a1.itemnumber, a2.itemnumber); 
}

sortByItemDescriptionAsc(a1: IDemandItemRouterOnHoldReport, a2: IDemandItemRouterOnHoldReport) { 
  return Helper.cmpString(a1.itemdescription, a2.itemdescription); 
}

sortByItemDescriptionDesc(a1: IDemandItemRouterOnHoldReport, a2: IDemandItemRouterOnHoldReport) {
  return Helper.cmpStringDesc(a1.itemdescription, a2.itemdescription); 
}

sortByOnHoldReasonAsc(a1: IDemandItemRouterOnHoldReport, a2: IDemandItemRouterOnHoldReport) { 
    return Helper.cmpString(a1.onholdreason, a2.onholdreason); 
}
  
sortByOnHoldReasonDesc(a1: IDemandItemRouterOnHoldReport, a2: IDemandItemRouterOnHoldReport) {
    return Helper.cmpStringDesc(a1.onholdreason, a2.onholdreason); 
}

sortByReportedAsc(a1: IDemandItemRouterOnHoldReport, a2: IDemandItemRouterOnHoldReport) { 
    return Helper.cmpDateTime(a1.reported, a2.reported); 
}
  
sortByReportedDesc(a1: IDemandItemRouterOnHoldReport, a2: IDemandItemRouterOnHoldReport) {
    return Helper.cmpDateTimeDesc(a1.reported, a2.reported); 
}

sortByDateTypeAsc(a1: IDemandItemRouterOnHoldReport, a2: IDemandItemRouterOnHoldReport) { 
    return Helper.cmpString(a1.datetype, a2.datetype); 
}
  
sortByDateTypeDesc(a1: IDemandItemRouterOnHoldReport, a2: IDemandItemRouterOnHoldReport) {
    return Helper.cmpStringDesc(a1.datetype, a2.datetype); 
}


sortByPriorityAsc(a1: IDemandItemRouterOnHoldReport, a2: IDemandItemRouterOnHoldReport) { 
  var cmp = a1.priority - a2.priority; 
  if (cmp == 0)
    cmp = Helper.cmpDateTime(a1.reported, a2.reported);
  return cmp;
}

sortByPriorityDesc(a1: IDemandItemRouterOnHoldReport, a2: IDemandItemRouterOnHoldReport) {
  var cmp = a2.priority - a1.priority; 
  if (cmp == 0)
    cmp = Helper.cmpDateTime(a2.reported, a1.reported);
  return cmp;
}


get isOverlayVisible() {
  return this.reportForm.nativeElement.offsetParent != null;
}

ngAfterContentChecked() {
  
}

 
ngAfterViewChecked() {
  const c1 = $('.col1').width();
  const c2 = $('.col2').width();
  const c3 = $('.col3').width();
  const c4 = $('.col4').width();
  const c5 = $('.col5').width();
  const c6 = $('.col6').width();

  $('#col1').width(c1 + 1);
  $('#col2').width(c2);
  $('#col3').width(c3);
  $('#col4').width(c4);
  $('#col5').width(c5);
  $('#col6').width(c6);
  $('.SearchBar').width(c1 + c2 + c3 + c4 + c5 + c6 + 32);

  var th = $('thead').width();
  $("#Cover").width(th);

 // $('#number').focus();
}
ngAfterViewInit() {
      console.log('Report.Component.AfterViewInit');      
      
      // runs every time the user re-sizes the window
          $(window).resize(function(){
        // gets td column widths
          const c1 = $('.col1').width();
          const c2 = $('.col2').width();
          const c3 = $('.col3').width();
          const c4 = $('.col4').width();
          const c5 = $('.col5').width();
          const c6 = $('.col6').width();
          const th = $('thead').width();
        // sets th column widths to match td's
          $("#Cover").width(th+1);
          $('#col1').width(c1 + 1);
          $('#col2').width(c2);
          $('#col3').width(c3);
          $('#col4').width(c4);
          $('#col5').width(c5);
          $('#col6').width(c6); 
          $('.SearchBar').width(c1 + c2 + c3 + c4 + c5 + c6 + 32);         
          console.log ('resize header occured');
          });
    }
    ngAfterContentInit() {
      $(document).ready(function(){
        $('.SearchField').focus();
      })
    }

}